import { SyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Center,
  InputGroup,
  Input,
  InputRightElement,
  Show,
  Spinner,
  useToast,
  Link,
  Flex,
} from "@chakra-ui/react";

import { CheckMark } from "icons";

import { chainSubscribeKeyMapping, validateEmail } from "@utils/common";
import { trackEvent } from "dapp-sdk-v2";

import {
  BNB,
  POLYGON,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from "@constants/constants";
import { SUBSCRIBE_EMAIL, SUBSCRIBE_PRIVACY } from "@constants/analytics";

import { StrapiSectionDetail } from "@customtypes/global";

import { subscribeEmail } from "@services/app";

// Components
import Icon from "@components/Icon";
import Section from "../Section";

interface NewsletterProps {
  sectionDetails: StrapiSectionDetail;
  subscribeForm: {
    placeholder?: string;
    submit_text?: string;
  };
}

const Newsletter = ({ sectionDetails, subscribeForm }: NewsletterProps) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (email) {
      const isValidEmail = validateEmail(email);

      if (isValidEmail) {
        setError("");
      } else {
        setError("Please enter correct email ID");
      }
    }
  }, [email]);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const token = process.env.NEXT_PUBLIC_TOKEN;
    trackEvent(SUBSCRIBE_EMAIL, { email_id: email });
    if (!validateEmail(email)) {
      setError("Please enter correct email ID");
      return;
    }
    if (token && chainSubscribeKeyMapping[token]) {
      setIsSubscribing(true);

      const formData: any = new FormData();

      formData.append("Email", email);
      formData.append(chainSubscribeKeyMapping[token], true);

      const response = await subscribeEmail(formData);

      if ((response as any).error) {
        toast({
          description: "Failed to subscribe",
          status: "error",
          duration: 2000,
          position: "top",
          isClosable: true,
        });
        setIsSubscribing(false);
        setEmail("");
        return;
      }

      toast({
        description: "Email subscribed successfully",
        status: "success",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      setIsSubscribing(false);
      setEmail("");
    }
  };

  return (
    <>
      <Section
        title={sectionDetails.title}
        subTitle={sectionDetails.sub_title}
        id={sectionDetails.section_id}
        py={{ base: "64px", lg: "160px" }}
      >
        <Box px={{ base: 4, lg: 0 }}>
          <Center mt={5} flexDir="column">
            <form onSubmit={handleSubmit}>
              <InputGroup
                size="lg"
                mt="0.5rem"
                mb="0"
                w={{ base: "320px", lg: "548px" }}
              >
                <Input
                  bg="backgroundPrimary"
                  type="email"
                  required
                  placeholder={subscribeForm?.placeholder}
                  value={email}
                  fontSize="1rem"
                  fontWeight="500"
                  h={{ base: "56px", xl: "58px" }}
                  pl="2rem"
                  borderRadius="10px"
                  border="1px solid rgba(20, 37, 40, 0.1)"
                  onChange={(e) => setEmail(e.target.value)}
                  _placeholder={{ color: "#1425284D" }}
                />

                <InputRightElement h="58px" w="128px" mr="5px" zIndex="0">
                  <Show above="xl">
                    <Button type="submit" p="11px 1.5rem" h={46}>
                      {isSubscribing ? (
                        <Spinner size="md" color="white" />
                      ) : (
                        <Text color="white">{subscribeForm?.submit_text}</Text>
                      )}
                    </Button>
                  </Show>
                </InputRightElement>
              </InputGroup>
              {error && (
                <Text textStyle="p14" color="#e53e3e" mt="0.5rem">
                  {error}
                </Text>
              )}
              <Show below="xl">
                <Center>
                  <Button
                    w="152px"
                    type="submit"
                    mt="1.5rem"
                    h="40px"
                    p="10px 2.5rem"
                  >
                    {isSubscribing ? (
                      <Spinner size="md" color="white" />
                    ) : (
                      <Text color="white">Subscribe</Text>
                    )}
                  </Button>
                </Center>
              </Show>
            </form>
            <Center
              mt="1.5rem"
              flexDir={{ base: "column", xl: "row" }}
              fontSize={{ base: "14px", xl: "16px" }}
              alignItems="center"
              fontWeight="500"
            >
              <Flex gap="4px">
                <Icon height="24px" width="24px" Icon={CheckMark} />
                <Text color="#8F8F8F">By subscribing you accept our</Text>
              </Flex>
              <Link
                ml={{ base: 0, xl: "0.5rem" }}
                mt={{ base: "2px", xl: 0 }}
                variant="secondary"
                color="primary"
                cursor="pointer"
                href={PRIVACY_POLICY_URL}
                isExternal={true}
                borderBottom="1px dashed"
                borderColor="primary"
                textDecor="none !important"
              >
                Privacy policy
              </Link>
              {[BNB, POLYGON].includes(process.env.NEXT_PUBLIC_TOKEN || "") && (
                <>
                  <Text
                    color="#8F8F8F"
                    ml={{ base: 0, lg: "0.5rem" }}
                    mt={{ base: "2px", lg: 0 }}
                  >
                    &
                  </Text>
                  <Link
                    ml={{ base: 0, lg: "0.5rem" }}
                    mt={{ base: "2px", lg: 0 }}
                    variant="secondary"
                    color="primary"
                    cursor="pointer"
                    href={TERMS_URL}
                    isExternal={true}
                    borderBottom="1px dashed"
                    borderColor="primary"
                    textDecor="none !important"
                    onClick={() => trackEvent(SUBSCRIBE_PRIVACY)}
                  >
                    *T&C
                  </Link>
                </>
              )}
            </Center>
          </Center>
        </Box>
      </Section>
    </>
  );
};

export default Newsletter;
