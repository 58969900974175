import { sentryErrorLog } from "@utils/sentryLog";

export const subscribeEmail = async (data: FormData) => {
  try {
    const response = await fetch(
      "https://script.google.com/macros/s/AKfycbxdo569bZMkTsQHuE3qFzO2dEwpoyOly6MbAlVUU2YlM-kMYUxcUMGYxL1_cksL1UsiPQ/exec",
      {
        method: "POST",
        body: data,
        redirect: "follow",
      }
    );

    return response;
  } catch (error) {
    sentryErrorLog(error);
    return {
      error,
    };
  }
};
